export const COPY_HOVER = [
  {
    // id: 1
    title: 'Refreshed Resume',
    description:
      'Our experts will update your resume and deliver it to you in a new Applicant Tracking System compliant template',
  },
  {
    // id: 2
    title: 'Recruiter Outreach / Distribution',
    description:
      "When you are ready we'll research, find, and send your resume to 200 recruiters actively looking for candidates with your skills and career goals.",
  },
  {
    // id: 3
    title: 'Career Services Platform',
    description:
      'Our platform and dedicated team handles everything - finding ideal jobs for you, applying for you, preparing you for interviews, and providing an analysis of salary offers to help you make the best decision.',
  },
  {
    // id: 4
    title: '10 Job Applications (we apply for you)',
    description:
      'Our specialists apply for you. This saves you time and avoids mistakes that can cost you the interview. Completing up to 10 applications within first 30 days of downloading your resume!',
  },
  {
    // id: 5
    title: 'World-class Resume Builder / Editor',
    description:
      'Get your new resume delivered in our easy to use resume builder. Quickly tailor resumes to specific jobs and change templates.',
  },
  {
    // id: 6
    title: 'Professionally Written Resume',
    description:
      "Our resumes are written by industry experts, formatted to catch employers' attention, and optimized for Application Tracking Systems (ATS).",
  },
  {
    // id: 7
    title: '20 Job Applications (we apply for you)',
    description:
      'Our specialists apply for you. This saves you time and avoids mistakes that can cost you the interview. Completing up to 20 applications within first 30 days of downloading your resume!',
  },
  {
    // id: 8
    title: 'LinkedIn Makeover',
    description:
      'Our LinkedIn experts will review and rewrite your profile - 97% of employers use LinkedIn.',
  },
  {
    // id: 9
    title: 'Cover Letter',
    description:
      'Employers are 40% more likely to read a resume with a cover letter.',
  },
  {
    // id: 10
    title: 'Professionally Written Resume (Top 20%)',
    description:
      "Our resumes are written by industry experts, formatted to catch employers' attention, and optimized for Application Tracking Systems (ATS).",
  },
  {
    // id: 11
    title: '40 Job Applications (we apply for you)',
    description:
      'Our specialists apply for you. This saves you time and avoids mistakes that can cost you the interview. Completing up to 40 applications within first 30 days of downloading your resume!',
  },
  {
    // id: 12
    title: 'Interview Coaching Session',
    description:
      '1 interview coaching session - Jobseekers who practice for interviews have a 96% higher success rate.',
  },
  {
    // id: 13
    title: '10-Min Video',
    description:
      '10-minute video from our professional resume reviewers. Targeted feedback on your resume, including areas to improve and actions to take.',
  },
  {
    // id: 14
    title: '40 Job Applications (we apply for you)',
    description:
      'Our specialists apply for you. This saves you time and avoids mistakes that can cost you the interview. Completing up to 20 applications within first 30 days of downloading your resume!',
  },
  {
    // id: 15
    title: '50 Job Applications (we apply for you)',
    description:
      'Our specialists apply for you. This saves you time and avoids mistakes that can cost you the interview. Completing up to 40 applications within first 30 days of downloading your resume!',
  },
  {
    // id: 16
    title: 'Interview Coaching Sessions',
    description:
      '2 interview coaching sessions - Jobseekers who practice for interviews have a 96% higher success rate.',
  },
  {
    // id: 17
    title: 'Professionally Written Resume (Top 1%)',
    description:
      "Our resumes are written by industry experts, formatted to catch employers' attention, and optimized for Application Tracking Systems (ATS).",
  },
  {
    // id: 18
    title: 'Professionally Written Resume (Top 10%)',
    description:
      "Our resumes are written by industry experts, formatted to catch employers' attention, and optimized for Application Tracking Systems (ATS).",
  },
  {
    // id: 19
    title: 'Professionally Written CV',
    description:
      "Our CV's are written by industry experts, formatted to catch employers' attention, and optimized for Application Tracking Systems (ATS).",
  },
  {
    // id: 20
    title: 'World-class CV Builder / Editor',
    description:
      'Get your new CV delivered in our easy to use CV builder. Quickly tailor CVs to specific jobs and change templates.',
  },
  {
    // id: 21
    title: 'Cover Letter',
    description: 'Employers are 40% more likely to read a CV with a cover letter.',
  },
  {
    // id: 22
    title: 'Professionally Written CV (Top 20%)',
    description:
      "Our CVs are written by industry experts, formatted to catch employers' attention, and optimized for Application Tracking Systems (ATS).",
  },
  {
    // id: 23
    title: 'Professionally Written CV (Top 10%)',
    description:
      "Our CVs are written by industry experts, formatted to catch employers' attention, and optimized for Application Tracking Systems (ATS).",
  },
  {
    // id: 24
    title: 'Refreshed CV',
    description:
      'Our experts will update your resume and deliver it to you in a new Applicant Tracking System compliant template.',
  },
  {
    // id: 25
    title: '60-day Interview Guarantee',
    description:
      'Not getting x2 interviews with your new Resume? We will write it again one time.',
  },
  {
    // id: 26
    title: '60-day Interview Guarantee',
    description:
      'Not getting x2 interviews with your new CV? We will write it again one time.',
  },
]
