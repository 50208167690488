import React, { useEffect, useState } from 'react'
import { Box, Container, Typography } from '@mui/material'
import { useSessionQuery } from '@talentinc/gatsby-theme-ecom/components/Providers/UserSession'
import { useBETelemetry } from '@talentinc/gatsby-theme-ecom/hooks/useTelemetry'
import useBrand from '@talentinc/gatsby-theme-ecom/hooks/useBrand'
import { useStyles } from './DynamicProductsSection.styles'
import { DynamicProductsSection as ContentfulDynamicProductsSection } from '../../types/landingPageV2'
import { usePricePoints } from './data/usePricePoints'
import { PackageCard } from './PackageCard'
import { useLeadEvent } from '../../hooks/useCheckoutEvent'
import { HeroV2 } from './HeroV2'

type Props = {
  pageKind: ContentfulDynamicProductsSection['pageKind']
}

export function DynamicProductsSection(props: Props) {
  const { pageKind } = props
  const { classes, cx } = useStyles({ pageKind })
  const brand = useBrand()

  const telemetry = useBETelemetry()

  const [debug, setDebug] = useState(false)
  const [segment, setSegment] = useState('')

  const sessionQuery = useSessionQuery()

  const { pricePoints, match } = usePricePoints({
    type: pageKind,
    segment: segment || sessionQuery.data?.segmentKey,
  })

  const isLoading = sessionQuery.isLoading && !segment

  useLeadEvent({ eventName: 'Checkout Page Viewed', pricePoints, isLoading })

  useEffect(() => {
    if (sessionQuery?.data?.segmentKey) {
      localStorage.setItem('segment', sessionQuery?.data?.segmentKey)
    }
  }, [sessionQuery.data])

  useEffect(() => {
    const tmp = localStorage.getItem('segment')

    if (tmp) {
      setSegment(tmp)
    }
  }, [])

  useEffect(() => {
    if (sessionQuery.isFetched) {
      telemetry.track({
        event: 'visit_plans_page',
        properties: {
          section: pageKind,
          segment: match,
        },
      })
    }
    // eslint-disable-next-line
  }, [sessionQuery.data, sessionQuery.isFetched])

  /**
   * DEBUG
   -------------------------------------------------------------------*/
  useEffect(() => {
    const sp = new URLSearchParams(window.location.search)
    setDebug(sp.get('debug') as any)
  }, [])

  const getPageLink = (page: string) => {
    return `/${page}${window.location.search}`
  }

  return (
    <Box className={classes.root}>
      <Container>
        {brand.isTopResume ? (
          <Box className={classes.tabs}>
            <button
              className={cx(
                classes.tab,
                pageKind === 'Resume Writing' ? 'active' : null
              )}
              onClick={() => {
                telemetry.track({
                  event: 'click_resume_writing',
                  properties: { section: pageKind },
                })
                window.location.href = getPageLink('resume-writing')
              }}
            >
              <span>Resume Writing</span>
            </button>
            <button
              className={cx(
                classes.tab,
                pageKind === 'Job Search' ? 'active' : null
              )}
              onClick={() => {
                telemetry.track({
                  event: 'click_job_search_services',
                  properties: { section: pageKind },
                })
                window.location.href = getPageLink('job-search')
                window.location.href = getPageLink('job-search')
              }}
            >
              <span>Job Search Services</span>
            </button>
          </Box>
        ) : null}

        <HeroV2 pageKind={pageKind} />

        {/* DEBUG
         * -------------------------------------------------------------------*/}
        {debug ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              gap: '3px',
              width: 'fit-content',
              marginLeft: 'auto',
            }}
          >
            <Typography variant="h5">DEBUG</Typography>
            <select
              value={segment}
              onChange={(e) => setSegment(e.target.value as any)}
            >
              <option label=""></option>
              {[
                'blue_collar',
                'academic',
                'white_collar_0_5',
                'white_collar_6_10',
                'white_collar_11',
                'unknown',
              ].map((i) => (
                <option key={i} label={i} value={i} />
              ))}
            </select>
            <small>
              API match: <b>{sessionQuery.data?.segmentKey}</b>
            </small>
            <small>
              React match: <b>{match}</b>
            </small>
          </Box>
        ) : null}

        <Box className={classes.grid} id="view_packages">
          {pricePoints.map((pp, i) => (
            <PackageCard
              key={pp.plan_code}
              pricePoint={pp}
              isRecommended={i === 1}
              isHorizontal={pricePoints.length - 1 === i}
              isLoading={isLoading}
              pageKind={pageKind}
              segment={segment || sessionQuery.data?.segmentKey || ''}
              isElite={i === 3}
            />
          ))}
        </Box>

        <Box className={classes.footer}>
          <Typography variant="h3">60-Day Interview Guarantee</Typography>
          <Typography>
            We're committed to helping our customers succeed. If you don't get called
            for an interview in 60-days, your rewrite is on us.
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}
