export const COPY = [
  {
    // id: 1
    description: 'Dedicated job search support for your success',
    selling_points: [
      'Professionally Written Resume Draft',
      'We Apply For You: 10 Applications',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Fundamentals for Greatness Includes',
      items: [0, 3, 1, 2, 4],
    },
  },
  {
    // id: 2
    description:
      'Professionally written resume to stand out, and dedicated job search support for your success',
    selling_points: [
      'Professionally Written Resume',
      'Cover Letter',
      'Linkedin Makeover',
      'We Apply For You: 20 Applications',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Professional Edge Includes',
      items: [5, 7, 8, 6, 1, 4, 2],
    },
  },
  {
    // id: 3
    description:
      'Expert-written professional story and complete job search support for your success',
    selling_points: [
      'Professionally Written Resume',
      'Cover Letter',
      'Linkedin Makeover',
      'We Apply For You: 20 Applications',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
      '60-day Interview Guarantee',
    ],
    hover: {
      phrase: 'Your Complete Path to Success Includes',
      items: [5, 8, 7, 6, 1, 4, 2, 24],
    },
  },
  {
    // id: 4
    description:
      'Get your resume written by our top writers, with dedicated support and expert interview coaching. Experience top-level guidance every step of the way.',
    selling_points: [
      'Professionally Written Resume',
      'Cover Letter',
      'LinkedIn Makeover',
      '1 Interview Coaching Session',
      'We Apply For You: 40 Applications',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
      '60-day Interview Guarantee',
    ],
    hover: {
      phrase: 'Your Apex Path to Success Includes',
      items: [5, 8, 7, 11, 10, 1, 4, 2, 24],
    },
  },
  {
    // id: 5
    description: 'An expertly written draft resume to perfect your story',
    selling_points: [
      'Professionally Written Resume Draft',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Advantage Path to Success Includes',
      items: [0, 4, 2],
    },
  },
  {
    // id: 6
    description:
      'An expertly written and keyword-optimized resume that sets you apart and distributed to recruiters',
    selling_points: [
      'Professionally Written Resume',
      'Cover Letter',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Premium Path to Success Includes',
      items: [5, 8, 4, 2],
    },
  },
  {
    // id: 7
    description:
      'An expertly handcrafted resume, cover letter, LinkedIn profile, and targeted recruiter outreach',
    selling_points: [
      'Professionally Written Resume',
      'Cover Letter',
      'Linkedin Makeover',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Ultimate Path to Success Includes',
      items: [5, 8, 7, 1, 4, 2],
    },
  },
  {
    // id: 8
    description:
      'Looking for a major career upgrade? With our expert writers, this package helps you rise above the competition.',
    selling_points: [
      'Professionally Written Resume',
      'Cover Letter',
      'LinkedIn Makeover',
      '1 Interview Coaching Session',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Elite Path to Success Includes',
      items: [5, 8, 7, 11, 1, 4, 2],
    },
  },
  {
    // id: 9
    description: 'Dedicated job search support for your success',
    selling_points: [
      '10-minute Video Review of your Resume',
      'Professionally Written Resume Draft',
      'We Apply For You: 10 Applications',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Fundamentals for Greatness Includes',
      items: [12, 0, 3, 1, 4, 2],
    },
  },
  {
    // id: 10
    description:
      'Professionally written resume to stand out, and dedicated job search support for your success',
    selling_points: [
      'Professionally Written Resume',
      'Cover Letter',
      'Linkedin Makeover',
      'We Apply For You: 20 Applications',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
      '60-day Interview Guarantee',
    ],
    hover: {
      phrase: 'Your Professional Edge Includes',
      items: [5, 8, 7, 6, 1, 4, 2, 24],
    },
  },
  {
    // id: 11
    description:
      'Expert-written professional story and complete job search support for your success',
    selling_points: [
      'Professionally Written Resume',
      'Cover Letter ',
      'Linkedin Makeover',
      'Interview Coaching Session',
      'We Apply For You: 40 Applications',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Complete Path to Success Includes',
      items: [5, 8, 7, 11, 13, 1, 4, 2],
    },
  },
  {
    // id: 12
    description:
      'Get your resume written by Top 20% writers, with dedicated support and expert interview coaching. Experience top-level guidance every step of the way.',
    selling_points: [
      'Top 10% of Writer Network',
      'Professionally Written Resume',
      'Cover Letter',
      'LinkedIn Makeover',
      '2 Interview Coaching Sessions',
      'We Apply For You: 50 Applications',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
      '60-day Interview Guarantee',
    ],
    hover: {
      phrase: 'Your Apex Path to Success Includes',
      items: [17, 8, 7, 15, 14, 1, 4, 2, 24],
    },
  },
  {
    // id: 13
    description: 'An expertly written draft resume to perfect your story',
    selling_points: [
      '10-minute Video Review of your Resume',
      'Professionally Written Resume Draft',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Advantage Path to Success Includes',
      items: [0, 12, 4, 2],
    },
  },
  {
    // id: 14
    description:
      'An expertly written and keyword-optimized resume that sets you apart and distributed to recruiters',
    selling_points: [
      'Professionally Written Resume',
      'Cover Letter',
      'Linkedin Makeover',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Premium Path to Success Includes',
      items: [5, 8, 7, 1, 4, 2],
    },
  },
  {
    // id: 15
    description:
      'An expertly handcrafted resume, cover letter, LinkedIn profile, and targeted recruiter outreach',
    selling_points: [
      'Professionally Written Resume',
      'Cover Letter',
      'Linkedin Makeover',
      'Interview Coaching Session',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
      '60-day Interview Guarantee',
    ],
    hover: {
      phrase: 'Your Ultimate Path to Success Includes',
      items: [5, 8, 7, 11, 1, 4, 2, 24],
    },
  },
  {
    // id: 16
    description:
      'Looking for a major career upgrade? With a dedicated account manager and a Top 20% expert writer, this package helps you rise above the competition',
    selling_points: [
      'Top 10% of Writer Network',
      'Professionally Written Resume',
      'Cover Letter',
      'LinkedIn Makeover',
      'Interview Coaching Session',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
      '60-day Interview Guarantee"',
    ],
    hover: {
      phrase: 'Your Elite Path to Success Includes',
      items: [17, 7, 8, 11, 1, 4, 2, 24],
    },
  },
  {
    // id: 17
    description:
      'Professionally written resume to stand out, and dedicated job search support for your success',
    selling_points: [
      'Professionally Written Resume',
      'Cover Letter',
      'Linkedin Makeover',
      'We Apply For You: 20 Applications',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Professional Edge Includes',
      items: [5, 8, 7, 6, 1, 4, 2],
    },
  },
  {
    // id: 18
    description: 'An expertly written draft resume to perfect your story',
    selling_points: [
      'Professionally Written Resume Draft',
      'Cover Letter',
      'Career Services Platform',
      'World-class Resume Builder/Editor',
    ],
    hover: {
      phrase: 'Your Advantage Path to Success Includes',
      items: [0, 8, 2, 4],
    },
  },
  {
    // id: 19
    description:
      'An expertly handcrafted resume, cover letter, LinkedIn profile, and targeted recruiter outreach',
    selling_points: [
      'Professionally Written Resume',
      'Cover Letter',
      'Linkedin Makeover',
      'Interview Coaching Session',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Ultimate Path to Success Includes',
      items: [5, 8, 7, 11, 1, 2, 4],
    },
  },
  {
    // id: 20
    description: 'Get traction with employers with a professional resume',
    selling_points: [
      'ATS-optimized resume',
      'Career Services Platform',
      '60-day Interview guarantee',
    ],
    hover: {
      phrase: '',
      items: [5, 4, 2, 24],
    },
  },
  {
    // id: 21
    description: 'Core documents to ensure you get the interview',
    selling_points: [
      'Professionally Written Resume',
      'Cover Letter',
      'Career Services Platform',
      '60-day Interview Guarantee',
    ],
    hover: {
      phrase: '',
      items: [5, 8, 2, 4, 24],
    },
  },
  {
    // id: 22
    description: 'Everything needed to stand out and get your target job',
    selling_points: [
      'Top 20% of Writer Network',
      'ATS-Optimized resume',
      'Cover Letter',
      'LinkedIn Makeover',
      'Career Services Platform',
      '60-day Interview Guarantee',
    ],
    hover: {
      phrase: '',
      items: [9, 8, 7, 2, 4, 24],
    },
  },
  {
    // id: 23
    description:
      'Looking for a larger career upgrade? With a dedicated account manager and an expert-writer from the top 10% in our network, this package has everything you need to rise above the competition in the job market.',
    selling_points: [
      'Top 10% of Writer Network',
      'Professionally Written Resume',
      'Cover Letter',
      'LinkedIn Makeover',
      '2 Interview Coaching Sessions',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
      '60-day Interview Guarantee',
    ],
    hover: {
      phrase: '',
      items: [17, 8, 7, 11, 4, 2, 24],
    },
  },
  {
    // id: 24
    description: 'Dedicated job search support for your success',
    selling_points: [
      'Professionally Written Resume',
      'Cover Letter',
      'We Apply For You: 20 Applications',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Fundamentals for Greatness Includes',
      items: [5, 8, 6, 1, 4, 2],
    },
  },
  {
    // id: 25
    description:
      'Expert-written professional story and complete job search support for your success',
    selling_points: [
      'Written by Top 20% of our Writer Network',
      'Cover Letter ',
      'Linkedin Makeover',
      'Interview Coaching Session',
      'We Apply For You: 40 Applications',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
      '60-day Interview Guarantee',
    ],
    hover: {
      phrase: 'Your Complete Path to Success Includes',
      items: [5, 8, 7, 11, 1, 4, 2, 24],
    },
  },
  {
    // id: 26
    description:
      'An expertly written and keyword-optimized resume that sets you apart and distributed to recruiters',
    selling_points: [
      'Professionally Written Resume (Top 20%)',
      'Cover Letter',
      'Linkedin Makeover',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Premium Path to Success Includes',
      items: [9, 8, 7, 1, 4, 2],
    },
  },
  {
    // id: 27
    description:
      'Looking for a major career upgrade? With a dedicated account manager and a top 1% expert writer, this package helps you rise above the competition.',
    selling_points: [
      'Top 20% of Writer Network',
      'Professionally Written Resume',
      'Cover Letter',
      'LinkedIn Makeover',
      '2 Interview Coaching Sessions',
      'We Apply For You: 40 Applications',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
      '60-day Interview Guarantee',
    ],
    hover: {
      phrase: 'Your Elite Path to Success Includes',
      items: [17, 2, 7, 8, 1, 15, 4, 24],
    },
  },
  {
    // id: 28
    description: 'Get traction with employers with a professional resume',
    selling_points: [
      'ATS-optimized resume',
      'We Apply For You: 20 Applications',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Fundamentals for Greatness Includes',
      items: [5, 6, 1, 2, 4],
    },
  },
  {
    // id: 29
    description: 'Core documents to ensure you get the interview',
    selling_points: [
      'Written by Top 20% of our Writer Network',
      'Cover Letter',
      'We Apply For You: 20 Applications',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Professional Edge Includes',
      items: [17, 8, 6, 1, 4, 2],
    },
  },
  {
    // id: 30
    description:
      'Expert-written professional story and complete job search support for your success',
    selling_points: [
      'Professionally Written Resume',
      'Cover Letter',
      'Linkedin Makeover',
      'We Apply For You: 20 Applications',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Complete Path to Success Includes',
      items: [5, 8, 7, 6, 1, 4, 2],
    },
  },
  {
    // id: 31
    description:
      'Get your resume written by our expert writers, with dedicated support and expert interview coaching. Experience top-level guidance every step of the way.',
    selling_points: [
      'Professionally Written Resume',
      'Cover Letter',
      'LinkedIn Makeover',
      'Interview Coaching Session',
      'We Apply For You: 40 Applications',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Apex Path to Success Includes',
      items: [5, 8, 7, 11, 10, 1, 4, 2],
    },
  },
  {
    // id: 32
    description:
      'An expertly handcrafted resume, cover letter, LinkedIn profile, and targeted recruiter outreach',
    selling_points: [
      'Professionally Written Resume',
      'Cover Letter',
      'Linkedin Makeover',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Ultimate Path to Success Includes',
      items: [5, 8, 7, 1, 4, 2],
    },
  },
  {
    // id: 33
    description:
      'Get your resume written by Top 20% writers, with dedicated support and expert interview coaching. Experience top-level guidance every step of the way.',
    selling_points: [
      'Top 20% of Writer Network',
      'Professionally Written Resume',
      'Cover Letter',
      'LinkedIn Makeover',
      '2 Interview Coaching Sessions',
      'We Apply For You: 40 Applications',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Apex Path to Success Includes',
      items: [9, 8, 7, 15, 10, 1, 4, 2],
    },
  },
  {
    // id: 34
    description:
      'Looking for a major career upgrade? With a dedicated account manager and a Top 20% expert writer, this package helps you rise above the competition.',
    selling_points: [
      'Top 20% of Writer Network',
      'Professionally Written Resume',
      'Cover Letter',
      'LinkedIn Makeover',
      'Interview Coaching Session',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Elite Path to Success Includes',
      items: [9, 8, 7, 11, 1, 4, 2],
    },
  },
  {
    // id: 35
    description:
      'Professionally written resume to stand out, and dedicated job search support for your success',
    selling_points: [
      'Written by Top 20% of our Writer Network',
      'Cover Letter',
      'Linkedin Makeover',
      'We Apply For You: 20 Applications',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Professional Edge Includes',
      items: [9, 8, 7, 6, 1, 4, 2],
    },
  },
  {
    // id: 36
    description:
      'Expert-written professional story and complete job search support for your success',
    selling_points: [
      'Written by Top 10% of our Writer Network',
      'Cover Letter',
      'Linkedin Makeover',
      'Interview Coaching Session',
      'We Apply For You: 40 Applications',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
      '60-day Interview Guarantee',
    ],
    hover: {
      phrase: 'Your Complete Path to Success Includes',
      items: [17, 8, 7, 11, 13, 1, 4, 2, 24],
    },
  },
  {
    // id: 37
    description: 'An expertly written draft resume to perfect your story',
    selling_points: [
      'Professionally Written Resume',
      'Cover Letter',
      'Career Services Platform',
      'World-class Resume Builder/Editor',
    ],
    hover: {
      phrase: 'Your Advantage Path to Success Includes',
      items: [5, 8, 4, 2],
    },
  },
  {
    // id: 38
    description:
      'An expertly handcrafted resume, cover letter, LinkedIn profile, and targeted recruiter outreach',
    selling_points: [
      'Written by Top 10% of our Writer Network',
      'Cover Letter',
      'Linkedin Makeover',
      'Interview Coaching Session',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
      '60-day Interview Guarantee',
    ],
    hover: {
      phrase: 'Your Ultimate Path to Success Includes',
      items: [17, 8, 7, 11, 1, 4, 2, 24],
    },
  },
  {
    // id: 39
    description: 'Get traction with employers with a professional CV.',
    selling_points: [
      'Top 20% of Writer Network',
      'Professionally Written CV',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      '60 Day Interview Guarantee',
    ],
    hover: {
      phrase: '',
      items: [21, 2, 19, 24],
    },
  },
  {
    // id: 40
    description: 'Core documents to ensure you get the interview.',
    selling_points: [
      'Top 20% of Writer Network',
      'Professionally Written CV',
      'Cover Letter',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      '60 Day Interview Guarantee',
    ],
    hover: {
      phrase: 'Your Premium Path to Success Includes',
      items: [21, 20, 2, 19, 24],
    },
  },
  {
    // id: 41
    description: 'Everything needed to stand out and get your target job.',
    selling_points: [
      'Top 10% of Writer Network',
      'Professionally Written CV',
      'Cover Letter',
      'LinkedIn Makeover',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      '60 Day Interview Guarantee',
    ],
    hover: {
      phrase: 'Your Ultimate Path to Success Includes',
      items: [22, 20, 7, 2, 19, 24],
    },
  },
  {
    // id: 42
    description:
      'Looking for a larger career upgrade? With a dedicated account manager and an expert-writer from the top 10% in our network, this package has everything you need to rise above the competition in the job market.',
    selling_points: [
      'Top 10% of Writer Network',
      'Professionally Written CV',
      'Cover Letter',
      'LinkedIn Makeover',
      '2 Interview Coaching Sessions',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      '60 Day Interview Guarantee',
    ],
    hover: {
      phrase: 'Your Elite Path to Success Includes',
      items: [22, 8, 7, 15, 2, 19, 25],
    },
  },
  {
    // id: 43
    description: 'Get traction with employers with a professional CV.',
    selling_points: [
      'Professionally Written CV',
      'We Apply For You: 3 Applications',
      'World Class CV Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Advantage Path to Success Includes',
      items: [23, 2, 19],
    },
  },
  {
    // id: 44
    description: 'Core documents to ensure you get the interview.',
    selling_points: [
      'Professionally Written CV',
      'We Apply For You: 3 Applications',
      'Cover Letter',
      'Linkedin',
      'World Class CV Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Premium Path to Success Includes',
      items: [18, 20, 7, 2, 19],
    },
  },
  {
    // id: 45
    description: 'Everything needed to stand out and get your target job.',
    selling_points: [
      'Top 20% of Writer Network',
      'ATS-Optimized CV',
      'Cover Letter',
      'LinkedIn Makeover',
      'We Apply For You: 3 Applications',
      'World Class CV Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Ultimate Path to Success Includes',
      items: [21, 20, 7, 2, 19],
    },
  },
  {
    // id: 46
    description:
      'Looking for a larger career upgrade? With a dedicated account manager and an expert-writer from the top 20% in our network, this package has everything you need to rise above the competition in the job market.',
    selling_points: [
      'Top 20% of Writer Network',
      'ATS-Optimized CV',
      'Cover Letter',
      'LinkedIn Makeover',
      'We Apply For You: 3 Applications',
      'World Class CV Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Elite Path to Success Includes',
      items: [21, 8, 7, 2, 4],
    },
  },
  {
    // id: 47
    description: 'Get traction with employers with a professional CV.',
    selling_points: [
      'Professionally Written CV',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      '60 Day Interview Guarantee',
    ],
    hover: {
      phrase: 'Your Advantage Path to Success Includes',
      items: [18, 2, 19, 24],
    },
  },
  {
    // id: 48
    description: 'Core documents to ensure you get the interview.',
    selling_points: [
      'Professionally Written CV',
      'Cover Letter',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      '60 Day Interview Guarantee',
    ],
    hover: {
      phrase: 'Your Premium Path to Success Includes',
      items: [18, 8, 2, 19, 24],
    },
  },
  {
    // id: 49
    description: 'Everything needed to stand out and get your target job.',
    selling_points: [
      'Top 20% of Writer Network',
      'Professionally Written CV',
      'Cover Letter',
      'LinkedIn Makeover',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      'World-class CV Builder / Editor',
      '60 Day Interview Guarantee',
    ],
    hover: {
      phrase: 'Your Ultimate Path to Success Includes',
      items: [21, 8, 7, 2, 19, 24],
    },
  },
  {
    // id: 50
    description:
      'Looking for a larger career upgrade? With a dedicated account manager and an expert-writer from the top 10% in our network, this package has everything you need to rise above the competition in the job market.',
    selling_points: [
      'Top 10% of Writer Network',
      'Professionally Written CV',
      'Cover Letter',
      'LinkedIn Makeover',
      '2 Interview Coaching Sessions',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      'World-class CV Builder / Editor',
      '60 Day Interview Guarantee',
    ],
    hover: {
      phrase: 'Your Elite Path to Success Includes',
      items: [22, 8, 7, 15, 2, 4],
    },
  },
  {
    // id: 51
    description: 'Core documents to ensure you get the interview.',
    selling_points: [
      'Professionally Written CV Draft',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      'World Class CV Builder/Editor',
    ],
    hover: {
      phrase: 'Your Advantage Path to Success Includes',
      items: [23, 2, 19],
    },
  },
  {
    // id: 52
    description: 'An expertly written CV to perfect your story',
    selling_points: [
      'Professionally Written CV',
      'Cover Letter',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      'World Class CV Builder/Editor',
    ],
    hover: {
      phrase: 'Your Premium Path to Success Includes',
      items: [18, 8, 2, 19],
    },
  },
  {
    // id: 53
    description: 'Everything needed to stand out and get your target job.',
    selling_points: [
      'Professionally Written CV',
      'Cover Letter',
      'LinkedIn Makeover',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      'World Class CV Builder/Editor',
    ],
    hover: {
      phrase: 'Your Ultimate Path to Success Includes',
      items: [18, 8, 7, 11, 2, 19],
    },
  },
  {
    // id: 54
    description:
      'Looking for a larger career upgrade? With a dedicated account manager and an expert-writer from the top 20% in our network, this package has everything you need to rise above the competition in the job market.',
    selling_points: [
      'Professionally Written CV',
      'Cover Letter',
      'LinkedIn Makeover',
      '1 Interview Coaching Session',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      'World Class CV Builder/Editor',
    ],
    hover: {
      phrase: 'Your Elite Path to Success Includes',
      items: [18, 20, 7, 11, 2, 4],
    },
  },
  {
    // id: 55
    description: 'Core documents to ensure you get the interview.',
    selling_points: [
      'Professionally Written CV Draft',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      'World Class CV Builder/Editor',
    ],
    hover: {
      phrase: 'Your Advantage Path to Success Includes',
      items: [23, 2, 19],
    },
  },
  {
    // id: 56
    description: 'An expertly written CV to perfect your story',
    selling_points: [
      'Professionally Written CV',
      'Cover Letter',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      'World Class CV Builder/Editor',
    ],
    hover: {
      phrase: 'Your Premium Path to Success Includes',
      items: [18, 8, 2, 19],
    },
  },
  {
    // id: 57
    description: 'Everything needed to stand out and get your target job.',
    selling_points: [
      'Professionally Written CV',
      'Cover Letter',
      'LinkedIn Makeover',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      'World Class CV Builder/Editor',
    ],
    hover: {
      phrase: 'Your Ultimate Path to Success Includes',
      items: [18, 8, 7, 11, 2, 19],
    },
  },
  {
    // id: 58
    description:
      'Looking for a larger career upgrade? With a dedicated account manager and an expert-writer from the top 20% in our network, this package has everything you need to rise above the competition in the job market.',
    selling_points: [
      'Professionally Written CV',
      'Cover Letter',
      'LinkedIn Makeover',
      '1 Interview Coaching Session',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      'World Class CV Builder/Editor',
    ],
    hover: {
      phrase: 'Your Elite Path to Success Includes',
      items: [18, 20, 7, 11, 2, 4],
    },
  },
  {
    // id: 59
    description: 'Core documents to ensure you get the interview.',
    selling_points: [
      '10-minute Video Review of your CV',
      'Professionally Written CV Draft',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      'World Class CV Builder/Editor',
    ],
    hover: {
      phrase: 'Your Advantage Path to Success Includes',
      items: [12, 23, 2, 19],
    },
  },
  {
    // id: 60
    description: 'An expertly written CV to perfect your story',
    selling_points: [
      'Professionally Written CV',
      'Cover Letter',
      'LinkedIn Makeover',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      'World Class CV Builder/Editor',
    ],
    hover: {
      phrase: 'Your Premium Path to Success Includes',
      items: [18, 8, 7, 11, 2, 19],
    },
  },
  {
    // id: 61
    description: 'Everything needed to stand out and get your target job.',
    selling_points: [
      'Professionally Written CV',
      'Cover Letter',
      'LinkedIn Makeover',
      'Interview Coaching Session',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      'World Class CV Builder/Editor',
    ],
    hover: {
      phrase: 'Your Ultimate Path to Success Includes',
      items: [18, 8, 7, 11, 2, 19],
    },
  },
  {
    // id: 62
    description:
      'Looking for a larger career upgrade? With a dedicated account manager and an expert-writer from the top 20% in our network, this package has everything you need to rise above the competition in the job market.',
    selling_points: [
      'Top 20% of Writer Network',
      'ATS-Optimized CV',
      'Cover Letter',
      'LinkedIn Makeover',
      '2 Interview Coaching Sessions',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      'World Class CV Builder/Editor',
      '60 Day Interview Guarantee',
    ],
    hover: {
      phrase: 'Your Elite Path to Success Includes',
      items: [21, 20, 7, 15, 2, 4, 25],
    },
  },
  {
    // id: 63
    description: 'Core documents to ensure you get the interview.',
    selling_points: [
      'Professionally Written CV',
      'Cover Letter',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      'World Class CV Builder/Editor',
    ],
    hover: {
      phrase: 'Your Advantage Path to Success Includes',
      items: [18, 8, 2, 19],
    },
  },
  {
    // id: 64
    description: 'An expertly written CV to perfect your story',
    selling_points: [
      'Top 20% of Writer Network',
      'ATS-Optimized CV',
      'Cover Letter',
      'LinkedIn Makeover',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      'World Class CV Builder/Editor',
    ],
    hover: {
      phrase: 'Your Premium Path to Success Includes',
      items: [21, 8, 7, 2, 19],
    },
  },
  {
    // id: 65
    description: 'Everything needed to stand out and get your target job.',
    selling_points: [
      'Top 10% of Writer Network',
      'ATS-Optimized CV',
      'Cover Letter',
      'LinkedIn Makeover',
      'Interview Coaching Session',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      'World Class CV Builder/Editor',
      '60 Day Interview Guarantee',
    ],
    hover: {
      phrase: 'Your Ultimate Path to Success Includes',
      items: [21, 8, 7, 11, 2, 19, 25],
    },
  },
  {
    // id: 66
    description:
      'Looking for a larger career upgrade? With a dedicated account manager and an expert-writer from the top 20% in our network, this package has everything you need to rise above the competition in the job market.',
    selling_points: [
      'Top 10% of Writer Network',
      'ATS-Optimized CV',
      'Cover Letter',
      'LinkedIn Makeover',
      '2 Interview Coaching Sessions',
      'We Apply For You: 3 Applications',
      'Career Services Platform',
      'World Class CV Builder/Editor',
      '60 Day Interview Guarantee',
    ],
    hover: {
      phrase: 'Your Elite Path to Success Includes',
      items: [22, 20, 7, 15, 2, 4, 25],
    },
  },
  {
    // id: 67
    description: 'Core documents to ensure you get the interview',
    selling_points: [
      'Professionally Written Resume',
      'Cover Letter',
      'We Apply For You: 20 Applications',
      'Recruiter Outreach',
      'World-class Resume Builder/Editor',
      'Career Services Platform',
    ],
    hover: {
      phrase: 'Your Professional Edge Includes',
      items: [5, 8, 4, 6, 1, 2],
    },
  },
]
