export const TR = {
  'Job Search': [
    { segment: 'blue_collar', plan_code: 'zDKPGvP', copy: 0 },
    { segment: 'blue_collar', plan_code: '5i3eGD6', copy: 1 },
    { segment: 'blue_collar', plan_code: 'vr1ICj4', copy: 2 },
    { segment: 'blue_collar', plan_code: '2FTtipG', copy: 3 },
    { segment: 'academic', plan_code: 'gwLlymI', copy: 8 },
    { segment: 'academic', plan_code: 'Ebts4J8', copy: 9 },
    { segment: 'academic', plan_code: 'akocSFR', copy: 10 },
    { segment: 'academic', plan_code: 'o2EbRsa', copy: 11 },
    { segment: 'white_collar_0_5', plan_code: 'zDKPGvP', copy: 0 },
    { segment: 'white_collar_0_5', plan_code: '5i3eGD6', copy: 66 },
    { segment: 'white_collar_0_5', plan_code: 'vr1ICj4', copy: 29 },
    { segment: 'white_collar_0_5', plan_code: '2FTtipG', copy: 30 },
    { segment: 'white_collar_6_10', plan_code: 'gwLlymI', copy: 8 },
    { segment: 'white_collar_6_10', plan_code: 'sMMEvhD', copy: 16 },
    { segment: 'white_collar_6_10', plan_code: 'H608kDp', copy: 10 },
    { segment: 'white_collar_6_10', plan_code: 'fgM8bYX', copy: 32 },
    { segment: 'white_collar_11', plan_code: '8lrtt61', copy: 23 },
    { segment: 'white_collar_11', plan_code: 'vtHuaUY', copy: 34 },
    { segment: 'white_collar_11', plan_code: 'RlqFsM', copy: 35 },
    { segment: 'white_collar_11', plan_code: 'RhKL1Ey', copy: 11 },
    { segment: 'unknown', plan_code: '33YdJzo', copy: 27 },
    { segment: 'unknown', plan_code: 'uxyqtoh', copy: 28 },
    { segment: 'unknown', plan_code: 'P7Hspwn', copy: 24 },
    { segment: 'unknown', plan_code: 'ywYBHY', copy: 11 },
  ],
  'Resume Writing': [
    { segment: 'blue_collar', plan_code: 'TixPqap', copy: 4 },
    { segment: 'blue_collar', plan_code: 'JnCMhOK', copy: 5 },
    { segment: 'blue_collar', plan_code: 'KYc6y9K', copy: 6 },
    { segment: 'blue_collar', plan_code: 'rRAnYrA', copy: 7 },
    { segment: 'academic', plan_code: 'z8087dD', copy: 12 },
    { segment: 'academic', plan_code: 'JJwsEhe', copy: 13 },
    { segment: 'academic', plan_code: 'IaSThof', copy: 14 },
    { segment: 'academic', plan_code: 'usmhdWf', copy: 15 },
    { segment: 'white_collar_0_5', plan_code: 'TixPqap', copy: 4 },
    { segment: 'white_collar_0_5', plan_code: 'x8YNJvu', copy: 5 },
    { segment: 'white_collar_0_5', plan_code: 'e8dTeZJ', copy: 31 },
    { segment: 'white_collar_0_5', plan_code: 'e5pg8Vz', copy: 7 },
    { segment: 'white_collar_6_10', plan_code: 'U0qhrDj', copy: 17 },
    { segment: 'white_collar_6_10', plan_code: 'q5yxMDL', copy: 13 },
    { segment: 'white_collar_6_10', plan_code: 'sQ4cZjV', copy: 18 },
    { segment: 'white_collar_6_10', plan_code: 'wp202Mb', copy: 33 },
    { segment: 'white_collar_11', plan_code: 'U0qhrDj', copy: 36 },
    { segment: 'white_collar_11', plan_code: 'AWxlCd2', copy: 25 },
    { segment: 'white_collar_11', plan_code: 'rJE2RBK', copy: 37 },
    { segment: 'white_collar_11', plan_code: 'acI3KbN', copy: 26 },
    { segment: 'unknown', plan_code: 'iFbBA45', copy: 19 },
    { segment: 'unknown', plan_code: 'ZT5KxC4', copy: 20 },
    { segment: 'unknown', plan_code: '1lNBpPM', copy: 21 },
    { segment: 'unknown', plan_code: 'IoLhUUN', copy: 22 },
  ],
}

export const TCV = {
  'Job Search': [],
  'Resume Writing': [
    { segment: 'unknown', plan_code: 'GaHUnrY', copy: 38 },
    { segment: 'unknown', plan_code: 'nHFk3XL', copy: 39 },
    { segment: 'unknown', plan_code: 'AK6WEzZ', copy: 40 },
    { segment: 'unknown', plan_code: '29X0wYc', copy: 41 },
    { segment: 'blue_collar', plan_code: 'viIldNi', copy: 42 },
    { segment: 'blue_collar', plan_code: '4Q0Cruu', copy: 43 },
    { segment: 'blue_collar', plan_code: 'ddTdGjD', copy: 44 },
    { segment: 'blue_collar', plan_code: 'EdHMWd5', copy: 45 },
  ],
}

export const TCVUK = {
  'Job Search': [],
  'Resume Writing': [
    { segment: 'unknown', plan_code: 'rG9ba0F', copy: 46 },
    { segment: 'unknown', plan_code: 'XQAE1Oj', copy: 47 },
    { segment: 'unknown', plan_code: 'x1sj4Ha', copy: 48 },
    { segment: 'unknown', plan_code: 'j9MGqo', copy: 49 },
    { segment: 'blue_collar', plan_code: '6Xz6I7W', copy: 50 },
    { segment: 'blue_collar', plan_code: 'ImHn9S', copy: 51 },
    { segment: 'blue_collar', plan_code: 'OAJUs0F', copy: 52 },
    { segment: 'blue_collar', plan_code: 'AzNhn2p', copy: 53 },
    { segment: 'white_collar_0_5', plan_code: '6Xz6I7W', copy: 54 },
    { segment: 'white_collar_0_5', plan_code: 'ImHn9S', copy: 55 },
    { segment: 'white_collar_0_5', plan_code: 'OAJUs0F', copy: 56 },
    { segment: 'white_collar_0_5', plan_code: 'AzNhn2p', copy: 57 },
    { segment: 'white_collar_6_10', plan_code: '7gdee8X', copy: 58 },
    { segment: 'white_collar_6_10', plan_code: '7BVOUt', copy: 59 },
    { segment: 'white_collar_6_10', plan_code: 'sjNytDC', copy: 60 },
    { segment: 'white_collar_6_10', plan_code: 'cpDVkbv', copy: 61 },
    { segment: 'white_collar_11', plan_code: 'd9Fug3K', copy: 62 },
    { segment: 'white_collar_11', plan_code: 'AonK397', copy: 63 },
    { segment: 'white_collar_11', plan_code: 'L0kuAQO', copy: 64 },
    { segment: 'white_collar_11', plan_code: '7uElWhO', copy: 65 },
  ],
}
