import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import TrustPilotRatingWidget from '@talentinc/gatsby-theme-ecom/components/TrustPilot/TrustPilotRatingWidget'
import useBrand from '@talentinc/gatsby-theme-ecom/hooks/useBrand'
import { useStyles } from './HeroV2.styles'

export type Props = {
  pageKind: string
}

export function HeroV2(props: Props) {
  const { pageKind } = props
  const { classes } = useStyles({ pageKind })
  const brand = useBrand()

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        {pageKind === 'Job Search' ? (
          <Box className={classes.title_wraper}>
            <Typography variant="h1" className={classes.title}>
              Let us manage your job search for you
            </Typography>
            <Typography variant="h6" className={classes.subtitle2}>
              Our team of experts will write your story, find jobs for you, apply for
              you, and prepare you to get the job you want.
            </Typography>
          </Box>
        ) : (
          <Box className={classes.title_wraper}>
            <Typography variant="h2" className={classes.subtitle1}>
              Professional {brand.flagshipProduct} Writing Services
            </Typography>
            <Typography variant="h1" className={classes.title}>
              Land your next job faster
            </Typography>
            <Typography variant="h6" className={classes.subtitle2}>
              Our services have helped over 1 million professionals land more
              interviews and get hired faster.
            </Typography>
          </Box>
        )}
        
        <TrustPilotRatingWidget
          reference={{ brand: 'TopResume', variant: 'FiveStars Alternative' } as any}
        />
      </Box>
    </Box>
  )
}
