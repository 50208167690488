import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles<{ pageKind: string }, 'tab' | 'grid'>()(
  (theme, { pageKind }, classes) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#eaf6ff',
      color: '#000',
      borderRadius: '0 0 32px 32px',
      padding: '24px',
      paddingTop: '16px',
      paddingBottom: '2rem',
      ...(pageKind !== 'Job Search' && {
        [theme.breakpoints.down('sm')]: {
          backgroundImage: 'none',
        },
        [theme.breakpoints.up('sm')]: {
          backgroundImage: 'url(https://images.ctfassets.net/7thvzrs93dvf/6uAyri84XvA1f59F72xnMR/eaf4ec8234768b1c20b756b31358b18a/background-hero.jpg)',
          backgroundPosition: 'right top',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto 700px',
        },
      }),
      ...(pageKind === 'Job Search' && {
        [theme.breakpoints.down('sm')]: {
          backgroundImage: 'none',
        },
        [theme.breakpoints.up('sm')]: {
          backgroundImage: 'url(https://images.ctfassets.net/7thvzrs93dvf/4SIxTqSl3BhJTOrlhLRPKM/cadfb571da6858e115f5f72faad86e12/background-hero-jss.jpg)',
          backgroundPosition: 'right top',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto 700px',
        },
      }),

      [theme.breakpoints.down('sm')]: {
        [`& .${classes.tab}`]: {
          fontSize: '16px',
          paddingLeft: '10px',
          paddingRight: '10px',
          paddingTop: '40px',
        },
      },

      [theme.breakpoints.down('md')]: {
        padding: '0',

        [`& .${classes.grid}`]: {
          padding: '32px 8px 8px 8px',
          gridTemplateColumns: '1fr',
          gridTemplateRows: 'repeat(4, minmax(225px, max-content))',
          gridTemplateAreas: `
            "A"
            "B"
            "C"
            "D"
          `,
          gap: '32px',
        },
      },

      [theme.breakpoints.down('xs')]: {
        paddingTop: '2rem!important',
        paddingBottom: '2rem',
      },
    },
    tabs: {
      display: 'flex',
      justifyContent: 'center',
      margin: '8px 0',
    },
    tab: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      color: theme.palette.text.primary,
      textDecoration: 'none',
      padding: '12px 34px',
      fontSize: '22px',
      fontWeight: 400,
      borderBottom: '1px solid gray',
      userSelect: 'none',

      '&:focus': { outline: 0 },

      '&.active': {
        borderBottom: '3px solid #1992f0',
        pointerEvents: 'none',
      },

      '& span': {
        opacity: 1,
        transition: 'opacity ease 300ms',
      },

      '&:hover span': {
        opacity: 0.5,
      },
    },

    grid: {
      scrollMarginTop: theme.spacing(8),
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: 'repeat(3, minmax(240px, 1fr))',
      gridTemplateRows: 'minmax(555px, max-content) minmax(220px, max-content)',
      gridTemplateAreas: `
      "A B C"
      "D D D"
    `,
      gap: '62px',
      paddingTop: '32px',

      '& > div:last-child': {
        gridArea: 'D',
      },
    },

    footer: {
      paddingTop: '40px',
      paddingBottom: '40px',
      textAlign: 'center',
    },
  })
)
